import React from 'react'
import Graph_danziger_TS from '../components/plots/PlotDanziger'
// import Spinner from '../components/layout/Spinner'

function PlotScreenDanz() {

const today = new Date()
const start = new Date(today)

start.setDate(start.getDate() - 5)
today.setDate(today.getDate() + 1)
// today.toISOString()
// yesterday.toISOString()
// console.log(`today: ${today.toISOString().split('T')[0]} and yesterday: ${yesterday.toISOString().split('T')[0]} 00:00:00`)
const start_time = start.toISOString().split('T')[0] + ' 00:00:00'
const end_time = today.toISOString().split('T')[0] + ' 00:00:00'

// const start_time = '2022-02-21 00:00:00'
// const end_time = '2022-02-26 00:00:00'



  return (
    <div>
      <h1>Danziger</h1>
      <h3>Vertical West lasers</h3>
     
      <Graph_danziger_TS gagelist= {{
        "primary" : [ 
          {"gage":"vnw_med","name":"VNW","table":"Dan501"},
        {"gage":"vsw_med","name":"VSW","table":"Dan601","tare":0}] ,
        "secondary" : [ 
          {"gage":"temp_ambient_avg", "name": "tempshort","table":"Dan602","tare":0}], "y_range": [0, 70],"title":"West Vertical"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'Dan602'} />
   

      <h3>Vertical East lasers</h3>
      <Graph_danziger_TS gagelist= {{"primary" : [ {"gage":"vne_med","name":"VNE","table":"Dan502"},{"gage":"vse_med","name":"VSE","table":"Dan602","tare":0}] ,"secondary" : [ {"gage":"temp_ambient_avg", "name": "Temp Amb","table":"Dan602","tare":0}], "y_range": [0, 70],"title":"East Vertical"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'Dan602'} />

      <h3>Horizontal West Lasers</h3>
      <Graph_danziger_TS gagelist= {{"primary" : [ {"gage":"tnw_med","name":"TNW","table":"Dan501"},{"gage":"tsw_med","name":"TSW","table":"Dan601","tare":0},{"gage":"lnw_ultra_in_med","name":"LNW","table":"Dan601","tare": 20.2},{"gage":"lsw_ultra_in_med","name":"LSW","table":"Dan601","tare":15.2}] ,"secondary" : [ {"gage":"temp_ambient_avg", "name": "Temp Amb","table":"Dan602","tare":0,"scalar":1}], "y_range": [0, 70],"title":"West Horizontal"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'Dan602'} />

      <h3>Horizontal East Lasers</h3>
      <Graph_danziger_TS gagelist= {{"primary" : [ {"gage":"lne_ultra_in_med","name":"LNE","table":"Dan602","tare":18.0},{"gage":"lse_ultra_in_med","name":"LSE","table":"Dan602","tare":"17.0"},{"gage":"tse_ultra_in_med","name":"TSE","table":"Dan602","tare":17.0}] ,"secondary" : [ {"gage":"temp_ambient_avg", "name": "Temp Amb","table":"Dan602","tare":0,"scalar":1}], "y_range": [0, 70],"title":"East Vertical"}} dateRange={{"dateRange" : [ start_time, end_time] }} table={'Dan602'} />

   
    </div>
  )


}

export default PlotScreenDanz
