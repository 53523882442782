import { render } from '@testing-library/react';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom'

const REACT_DJANGO = process.env.REACT_APP_DJANGO

let url = REACT_DJANGO +'/api/danziger/lifts/'


const ListLifts = ()=>{

  const [data,setData] = useState({})
  const [isLoading, setLoading] = useState(true);

  useEffect(() =>{

  const getLifts = async () =>{

  const response = await fetch(url,{
    method:'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      // 'Authorization':'Bearer '+this.token
    },
    })
    
    const resopnseJson = await response.json();
    console.log("json", resopnseJson);
    setData(resopnseJson);
    setLoading(false);
  }
  getLifts()
  
},[])
  
  



//{data?.lifttime}
//<pre>{JSON.stringify(data?.lifttime, null, 2) }</pre>

  // return ( 
  //   <div>
  //     <table>
  //       <tr><th>Lift Times</th></tr>
  //       <tbody>
  //       {data?.lifttime.map(item=>{
  //           return(
  //             <tr><Link to="/danzEvent" state={{eventtime:{item}}}>{item}</Link></tr>
  //           )
  //         })}
  //       </tbody>
  //     </table>
  //   </div>
  //   )

  if (isLoading) {
    return (
      <div>loading

    </div>
    )
  } else {

    return (
         <div>
      <table>
        <tr><th>Lift Times</th></tr>
        <tbody>
        {data?.lifttime.map(item=>{
            return(
              <tr><Link to="/danzEvent" state={{eventtime:{item}}}>{item}</Link></tr>
            )
          })}
        </tbody>
      </table>
    </div>
)

}
}

export default ListLifts